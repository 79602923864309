var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pt-0",attrs:{"aria-disabled":_vm.disabled}},[(!_vm.minimalInline)?_c('v-card-subtitle',{staticClass:"font-weight-bold pl-0 pb-2 pt-0"},[_vm._t("subtitle",function(){return [_vm._v(" "+_vm._s(_vm.subtitle ? _vm.subtitle : _vm.$t('newDate'))+": ")]})],2):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 inline-buttons",attrs:{"disabled":_vm.disabled,"color":"blue lighten-5","small":"","elevation":"0"},on:{"click":function($event){_vm.showDatePicker = true}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" "+_vm._s(_vm.formattedDate || _vm.$t('inlineDateTimeEditor.date'))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('inlineDateTimeEditor.tooltips.editDate'))+" ")])]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c('v-date-picker',{attrs:{"min":_vm.minDate,"max":_vm.maxDate,"date":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancelUpdate()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.confirmDate()}}},[_vm._v(" "+_vm._s(_vm.$t('done'))+" ")])],1)],1),(!_vm.noTime)?[_vm._v(" "+_vm._s(_vm.$t('inlineDateTimeEditor.at'))+" ")]:_vm._e(),(!_vm.noTime)?_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mr-1 inline-buttons",attrs:{"disabled":_vm.disabled,"small":"","elevation":"0","color":"blue lighten-5"},on:{"click":function($event){_vm.showTimePicker = true}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" "+_vm._s(_vm.formattedTime || _vm.$t('inlineDateTimeEditor.time'))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('inlineDateTimeEditor.tooltips.editTime'))+" ")])]}}],null,false,2967677269),model:{value:(_vm.showTimePicker),callback:function ($$v) {_vm.showTimePicker=$$v},expression:"showTimePicker"}},[_c('v-time-picker',{attrs:{"ampm-in-title":_vm.ampmInTitle,"format":_vm.timeFormat},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancelUpdate()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.confirmTime()}}},[_vm._v(" "+_vm._s(_vm.$t('done'))+" ")])],1)],1):_vm._e(),(!_vm.minimalInline && _vm.formattedNewDateFromOld)?_c('span',[_vm._v(_vm._s(_vm.formattedNewDateFromOld))]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }