












import {defineComponent, PropType} from '@vue/composition-api';
import {useVModel} from '@vueuse/core';

export default defineComponent({
  name: 'CourseCreationWizardLabTutorialToggle',
  props: {
    useLabTutorialCourse: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const useLabTutorialCourseModel = useVModel(props, 'useLabTutorialCourse');

    return {
      useLabTutorialCourseModel,
    };
  },
});
