import {makeGlobalSingleton} from '@/container';
import Institution from '@/institutions/models/Institution';
import {computed} from '@vue/composition-api';
import {useThrottleFn} from '@vueuse/core';

export const UseAllInstitutions = makeGlobalSingleton(() => makeUseAllInstitutions());

function makeUseAllInstitutions() {
  const institutions = computed(() => Institution.query().all());

  async function forceFetchInstitutions() {
    return Institution.api.fetch();
  }

  const fetchInstitutions = useThrottleFn(forceFetchInstitutions, 1000 * 60 * 5, false);

  return {
    institutions,
    fetchInstitutions,
    forceFetchInstitutions,
  };
}
