









import {computed, defineComponent, PropType} from '@vue/composition-api';
import {EditableCourse} from '@/courses/composables/UseCourseEditor';
import {COURSE_HOME} from '@/router/route-names';

export default defineComponent({
  name: 'CourseCreationWizardSuccess',
  props: {
    course: {
      type: Object as PropType<EditableCourse>,
      required: true,
    },
  },
  setup(props) {
    return {
      courseTo: computed(() => ({name: COURSE_HOME, params: {courseId: props.course.id}})),
    };
  },
});
