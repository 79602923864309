



































































































import {computed, defineComponent, PropType, ref, toRef} from '@vue/composition-api';
import {Course} from '@/courses/models';
import {inject} from '@/container';
import {UseCourseEditor} from '@/courses/composables/UseCourseEditor';
import StembleCard from '@/common/components/StembleCard.vue';
import DateTimePicker from '@/datetime/components/DateTimePicker.vue';
import CourseDataForm from '@/courses/components/CourseDataForm.vue';
import CourseSectionsDataForm from '@/courses/components/CourseSectionsDataForm.vue';
import Gate from '@/permissions/Gate';
import {UseAllInstitutions} from '@/institutions/composables/UseAllInstitutions';
import CourseRole from '@/users/models/CourseRole';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import SuccessSnackbarPlugin from '@/common/plugins/success-snackbar';
import {useVModel} from '@vueuse/core';
import {DASHBOARD} from '@/router/route-names';
import router from '@/router';

export default defineComponent({
  name: 'CourseAdvancedEditor',
  components: {
    CourseSectionsDataForm,
    CourseDataForm,
    DateTimePicker,
    StembleCard,
  },
  props: {
    course: {
      type: Course as PropType<Course | null>,
      default: null,
    },
  },
  setup(props) {
    const $gate = inject(Gate.injectable);
    const $errorReporting = inject(ErrorReportingService);
    const canEditSubCourse = computed(() =>
      props.course?.id
        ? $gate.can('update', 'Course', props.course.id)
        : $gate.can('create', 'Course')
    );

    const {save, ...editor} = inject(UseCourseEditor, toRef(props, 'course'), {
      supportsSubCourse: canEditSubCourse,
    });
    const {fetchInstitutions} = inject(UseAllInstitutions);
    const useWizard = ref(false);
    const isNew = computed(() => props.course === null);

    const isCourseFormValid = ref(null);
    const isCourseSectionsFormValid = ref(null);
    const isSubCourseFormValid = ref(null);
    const isSubCourseSectionsFormValid = ref(null);
    const isCourseCreated = ref<boolean>(false);
    const allValidationValid = computed(
      () =>
        isCourseFormValid.value &&
        isCourseSectionsFormValid.value &&
        (editor.editableCourse.value.subCourse === null ||
          (isSubCourseFormValid.value && isSubCourseSectionsFormValid.value))
    );

    async function saveCourse() {
      isCourseCreated.value = isNew.value;
      return save()
        ?.then(async () => {
          // Get the users new course role so it shows up in the side bar.
          await CourseRole.api.fetch({user: $gate.user?.id});
          (SuccessSnackbarPlugin as any).snackbar.open({
            messageTranslationKey: 'courseSettings.settingsSaved',
            showButton: true,
            color: 'success',
            timeout: 3000,
          });
        })
        .catch((error) => {
          isCourseCreated.value = false;
          return $errorReporting.catchError(error);
        });
    }

    function resetForm() {
      editor.reset();
      isCourseCreated.value = false;
    }
    fetchInstitutions();

    return {
      ...editor,
      isNew,
      useWizard,
      allValidationValid,
      isCourseFormValid,
      isCourseSectionsFormValid,
      isSubCourseFormValid,
      isSubCourseSectionsFormValid,
      canEditSubCourse,
      saveCourse,
      isCourseCreated,
      resetForm,
    };
  },
});
