






























import {defineComponent, PropType} from '@vue/composition-api';
import DateTimePicker from '@/datetime/components/DateTimePicker.vue';
import {useVModel} from '@vueuse/core';
import {validationObserverRef} from '@/form-validation/utils/validationObserverRef';
import {useEmitIsValid} from '@/form-validation/composables/useEmitIsValid';
import {useIsValidRef} from '@/form-validation/composables/useIsValidRef';
import DateTimeInlineEditor from '@/datetime/components/DateTimeInlineEditor.vue';
import {courseValidationRules} from '@/courses/form-validation';

export default defineComponent({
  name: 'CourseCreationWizardDates',
  components: {DateTimeInlineEditor, DateTimePicker},
  props: {
    startDate: {
      type: String as PropType<string>,
      default: null,
    },
    endDate: {
      type: String as PropType<string>,
      default: null,
    },
  },
  setup(props) {
    const startDateModel = useVModel(props, 'startDate');
    const endDateModel = useVModel(props, 'endDate');

    const validationObserver = validationObserverRef();
    useEmitIsValid(useIsValidRef(validationObserver));

    return {
      courseValidationRules,
      startDateModel,
      endDateModel,
      validationObserver,
    };
  },
});
