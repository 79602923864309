














































import {defineComponent, PropType, toRef} from '@vue/composition-api';
import {inject} from '@/container';
import {UseInstitutionEditor, events} from '@/institutions/composables/UseInstitutionEditor';
import Institution from '@/institutions/models/Institution';
import {institutionValidationRules} from '@/institutions/form-validation/institutionValidationRules';

export default defineComponent({
  name: 'InstitutionEditor',
  emits: Object.values(events),
  props: {
    institution: {
      type: Object as PropType<Institution>,
      required: false,
      default: null,
    },
    defaultName: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  setup(props, {emit}) {
    const {
      editableInstitution,
      createInstitution,
      patchInstitution,
      clearInstitution,
      resetChanges,
      isSaving,
      isNew,
      validationObserver,
      isValid,
    } = inject(
      UseInstitutionEditor,
      emit,
      toRef(props, 'institution'),
      toRef(props, 'defaultName')
    );

    return {
      isNew,
      editableInstitution,
      createInstitution,
      patchInstitution,
      clearInstitution,
      isSaving,
      resetChanges,
      validationObserver,
      isValid,
      institutionValidationRules,
    };
  },
});
