import {inject, makeDependency} from '@/container';
import {computed, ref, Ref, SetupContext} from '@vue/composition-api';
import Institution from '@/institutions/models/Institution';
import {LoadingFlagsService} from '@/loading/services/LoadingFlagsService';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import {DependencyType} from '@/container/types/DependencyType';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {useEntityEditor} from '@/common/composables/useEntityEditor';
import {validationObserverRef} from '@/form-validation/utils/validationObserverRef';
import {useIsValidRef} from '@/form-validation/composables/useIsValidRef';

export type EditableInstitution = Pick<Institution, 'id' | 'name' | 'abbreviation'>;

export const events = {
  INSTITUTION_EDITOR_INSTITUTION_CREATED: 'institution-created',
};

export const UseInstitutionEditor = makeDependency(
  (
    emit: SetupContext['emit'],
    institution?: Ref<Institution | null>,
    defaultName?: Ref<string>
  ) => {
    return makeInstitutionEditor(
      institution ?? ref(null),
      inject(LoadingFlagsService),
      inject(ErrorReportingService),
      emit,
      defaultName
    );
  }
);

export function makeInstitutionEditor(
  institution: Ref<Institution | null>,
  loadingFlagsService: DependencyType<typeof LoadingFlagsService>,
  errorReportingService: DependencyType<typeof ErrorReportingService>,
  emit: SetupContext['emit'],
  defaultName: Ref<string | null> = ref(null)
) {
  const isSaving = computed(
    () =>
      loadingFlagsService.isLoading(LoadingFlag.InstitutionEditorPatchInstitution) ||
      loadingFlagsService.isLoading(LoadingFlag.InstitutionEditorCreateInstitution)
  );

  const institutionId = ref(0);

  function refInitializer(): EditableInstitution {
    return {
      id: 0,
      name: defaultName.value ?? '',
      abbreviation: '',
    };
  }

  function cloneFn(institution?: EditableInstitution | null): EditableInstitution {
    if (institution) {
      return {
        id: institution.id,
        name: institution.name,
        abbreviation: institution.abbreviation,
      };
    }

    return refInitializer();
  }

  async function patchFn(institution: EditableInstitution) {
    return loadingFlagsService
      .loadingHandler(LoadingFlag.InstitutionEditorPatchInstitution, () =>
        Institution.api.patch(institution.id, institution)
      )
      .catch(errorReportingService.errorDialogHandler);
  }

  async function createFn(institution: EditableInstitution) {
    return loadingFlagsService.loadingHandler(LoadingFlag.InstitutionEditorCreateInstitution, () =>
      Institution.api
        .create(institution)
        .then((collections) => {
          const createdInstitutionId = (collections['Institution'][0] as Institution).id;
          emit(events.INSTITUTION_EDITOR_INSTITUTION_CREATED, createdInstitutionId);
          institutionId.value = createdInstitutionId;
        })
        .catch(errorReportingService.errorDialogHandler)
    );
  }

  const institutionComputed = computed(() => {
    if (institutionId.value) {
      const foundInstitution = Institution.find(institutionId.value);

      if (foundInstitution) {
        return foundInstitution;
      }
    }
    return institution.value;
  });

  const isNewRef = computed(() => !institutionId.value);

  const {watchedRef, editableEntity, create, patch, clear, reset} = useEntityEditor({
    watchedRef: institutionComputed,
    isNewRef,
    createFn,
    cloneFn,
    patchFn,
  });

  const validationObserver = validationObserverRef();
  const isValid = useIsValidRef(validationObserver);

  return {
    institution: watchedRef,
    editableInstitution: editableEntity,
    isSaving,
    patchInstitution: patch,
    createInstitution: create,
    clearInstitution: clear,
    resetChanges: reset,
    isNew: isNewRef,
    validationObserver,
    isValid,
  };
}
