import {inject, makeDependency} from '@/container';
import {UseAllInstitutions} from '@/institutions/composables/UseAllInstitutions';
import {Ref, ref} from '@vue/composition-api';

export const UseInstitutionSelector = makeDependency((internalValue: Ref<number | null>) =>
  makeUseInstitutionSelector(internalValue)
);

function makeUseInstitutionSelector(internalValue: Ref<number | null>) {
  const {institutions, fetchInstitutions} = inject(UseAllInstitutions);
  const searchTerm = ref(null);
  const createInstitutionDialog = ref(false);

  function handledCreatedInstitution(event: number) {
    internalValue.value = event;
    createInstitutionDialog.value = false;
  }

  return {
    institutions,
    fetchInstitutions,
    searchTerm,
    internalValue,
    createInstitutionDialog,
    handledCreatedInstitution,
  };
}
