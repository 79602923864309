













import {defineComponent, PropType} from '@vue/composition-api';
import {useVModel} from '@vueuse/core';
import {courseValidationRules} from '@/courses/form-validation';
import {useIsValidRef} from '@/form-validation/composables/useIsValidRef';
import {useEmitIsValid} from '@/form-validation/composables/useEmitIsValid';
import {validationObserverRef} from '@/form-validation/utils/validationObserverRef';
import InstitutionSelector from '@/institutions/components/InstitutionSelector.vue';

export default defineComponent({
  name: 'CourseCreationWizardInstitution',
  components: {InstitutionSelector},
  props: {
    institutionId: {
      type: Number as PropType<number>,
      default: null,
    },
  },
  setup(props, {emit}) {
    const institutionIdModel = useVModel(props, 'institutionId', emit);

    const validationObserver = validationObserverRef();
    useEmitIsValid(useIsValidRef(validationObserver));

    return {
      validationObserver,
      courseValidationRules,
      institutionIdModel,
    };
  },
});
