import {inject, makeDependency} from '@/container';
import {computed, ref, Ref, toRef, watch} from '@vue/composition-api';
import {useTranslate} from '@/locales/composables/i18n';
import Gate from '@/permissions/Gate';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import {UseCourseEditor} from '@/courses/composables/UseCourseEditor';
import CourseCreationWizardBasicMetadata from '@/courses/components/CourseCreationWizardBasicMetadata.vue';
import CourseCreationWizardInstitution from '@/courses/components/CourseCreationWizardInstitution.vue';
import CourseCreationWizardSections from '@/courses/components/CourseCreationWizardSections.vue';
import CourseCreationWizardDates from '@/courses/components/CourseCreationWizardDates.vue';
import CourseCreationWizardLabTutorialToggle from '@/courses/components/CourseCreationWizardLabTutorialToggle.vue';
import CourseCreationWizardSummary from '@/courses/components/CourseCreationWizardSummary.vue';
import CourseCreationWizardSuccess from '@/courses/components/CourseCreationWizardSuccess.vue';
import CourseRole from '@/users/models/CourseRole';
import {DefaultPolicyMap} from '@/permissions/types/DefaultPolicyMap';
import {DependencyType} from '@/container/types/DependencyType';
import {Course} from '@/courses/models';
import {CourseWizardScreen} from '@/courses/types/CourseWizardScreen';
import {FeatureFlag, FeatureFlagService} from '@/feature-flags';
import SuccessSnackbarPlugin from '@/common/plugins/success-snackbar';

export const UseCourseCreationWizard = makeDependency((course: Ref<Course | null>) =>
  makeCourseCreationWizard(
    course,
    useTranslate(),
    inject(Gate.injectable),
    inject(ErrorReportingService),
    inject(FeatureFlagService)
  )
);

export function makeCourseCreationWizard(
  course: Ref<Course | null>,
  $t: ReturnType<typeof useTranslate>,
  $gate: Gate<DefaultPolicyMap>,
  $errorReporting: DependencyType<typeof ErrorReportingService>,
  $featureFlags: DependencyType<typeof FeatureFlagService>
) {
  const currentWizardStep = ref(1);

  const {editableCourse, save, isSaving, addSubCourse, removeSubCourse} = inject(
    UseCourseEditor,
    course
  );

  const useLabTutorialCourse = computed({
    get() {
      return editableCourse.value.subCourse !== null;
    },
    set: setHasSubCourse,
  });

  const saveScreenName = $t('courseWizard.screens.summary.title').toString();

  const screens: Ref<CourseWizardScreen[]> = computed(() => {
    const result: CourseWizardScreen[] = [
      {
        name: $t('courseWizard.screens.basicMetadata.title').toString(),
        component: CourseCreationWizardBasicMetadata,
        syncProps: {
          name: toRef(editableCourse.value, 'name'),
          courseCode: toRef(editableCourse.value, 'courseCode'),
        },
      },
    ];

    if ($featureFlags.isEnabled(FeatureFlag.InstitutionCreatorDropdown)) {
      result.push({
        name: $t('courseWizard.screens.institution.title').toString(),
        component: CourseCreationWizardInstitution,
        syncProps: {
          institutionId: toRef(editableCourse.value, 'institutionId'),
        },
      });
    }

    result.push(
      {
        name: $t('courseWizard.screens.sections.title').toString(),
        component: CourseCreationWizardSections,
        syncProps: {
          sections: toRef(editableCourse.value, 'sections'),
        },
      },
      {
        name: $t('courseWizard.screens.dates.title').toString(),
        component: CourseCreationWizardDates,
        syncProps: {
          startDate: toRef(editableCourse.value, 'startDate'),
          endDate: toRef(editableCourse.value, 'endDate'),
        },
      },
      {
        name: $t('courseWizard.screens.labTutorialToggle.title').toString(),
        component: CourseCreationWizardLabTutorialToggle,
        syncProps: {
          useLabTutorialCourse,
        },
      }
    );

    if (editableCourse.value.subCourse) {
      result.push(
        {
          name: $t('courseWizard.screens.subCourseBasicMetadata.title').toString(),
          component: CourseCreationWizardBasicMetadata,
          syncProps: {
            name: toRef(editableCourse.value.subCourse, 'name'),
            courseCode: toRef(editableCourse.value.subCourse, 'courseCode'),
          },
        },
        {
          name: $t('courseWizard.screens.subCourseSections.title').toString(),
          component: CourseCreationWizardSections,
          syncProps: {
            sections: toRef(editableCourse.value.subCourse, 'sections'),
          },
        },
        {
          name: $t('courseWizard.screens.subCourseDates.title').toString(),
          component: CourseCreationWizardDates,
          syncProps: {
            startDate: toRef(editableCourse.value.subCourse, 'startDate'),
            endDate: toRef(editableCourse.value.subCourse, 'endDate'),
          },
        }
      );
    }

    result.push(
      {
        name: saveScreenName,
        component: CourseCreationWizardSummary,
        syncProps: {
          course: editableCourse,
        },
      },
      {
        name: $t('courseWizard.screens.finish.title').toString(),
        component: CourseCreationWizardSuccess,
        syncProps: {
          course: editableCourse,
        },
      }
    );
    return result;
  });

  const currentScreen = computed(() => screens.value[currentWizardStep.value - 1]);

  const isValidFlags = ref(Object.fromEntries(screens.value.map((s) => [s.name, true])));

  const isNextDisabled = computed(() => {
    if (currentWizardStep.value >= screens.value.length || isSaving.value) {
      return true;
    } else if (!isValidFlags.value[currentScreen.value.name]) {
      return true;
    }
    return false;
  });

  function makePropBindings(syncProps: CourseWizardScreen['syncProps']): Record<string, any> {
    if (!syncProps) {
      return {};
    }
    return Object.fromEntries(
      Object.keys(syncProps).map((propName: string) => [propName, syncProps[propName].value])
    );
  }

  function makeEventListeners(syncProps: CourseWizardScreen['syncProps']): Record<string, any> {
    if (!syncProps) {
      return {};
    }
    return Object.fromEntries(
      Object.keys(syncProps).map((propName: string) => [
        `update:${propName}`,
        (value: any) => {
          syncProps[propName].value = value;
        },
      ])
    );
  }

  function setHasSubCourse(value: boolean) {
    if (value) {
      addSubCourse();
    } else {
      removeSubCourse();
    }
    isValidFlags.value = Object.fromEntries(
      screens.value.map((s) => [s.name, isValidFlags.value[s.name] ?? true])
    );
  }

  async function saveCourse() {
    return save()
      ?.then(async () => {
        // Get the users new course role so it shows up in the side bar.
        await CourseRole.api.fetch({user: $gate.user?.id});
        (SuccessSnackbarPlugin as any).snackbar.open({
          messageTranslationKey: 'courseSettings.settingsSaved',
          showButton: true,
          color: 'success',
          timeout: 3000,
        });
        currentWizardStep.value++;
      })
      .catch($errorReporting.catchError);
  }

  function previousStep() {
    if (currentWizardStep.value > 1) {
      currentWizardStep.value--;
    }
  }

  function nextStep() {
    if (currentScreen.value.name === saveScreenName) {
      saveCourse();
    } else if (currentWizardStep.value < screens.value.length) {
      currentWizardStep.value++;
    }
  }

  // Syncs institution ID if it changes
  watch(
    () => editableCourse.value.institutionId,
    (newValue: number) => {
      if (editableCourse.value.subCourse) {
        editableCourse.value.subCourse.institutionId = newValue;
      }
    }
  );

  return {
    editableCourse,
    currentWizardStep,
    screens,
    saveScreenName,
    isSaving,
    isValidFlags,
    isNextDisabled,
    makePropBindings,
    makeEventListeners,
    previousStep,
    nextStep,
  };
}
