






















































import {defineComponent, PropType} from '@vue/composition-api';
import {EditableCourse} from '@/courses/composables/UseCourseEditor';
import {useVModel} from '@vueuse/core';
import DateTimePicker from '@/datetime/components/DateTimePicker.vue';
import {cloneDeep} from 'lodash';
import {useSyncEditableObject} from '@/common/composables/useSyncEditableObject';
import {useEmitIsValid} from '@/form-validation/composables/useEmitIsValid';
import {useIsValidRef} from '@/form-validation/composables/useIsValidRef';
import {validationObserverRef} from '@/form-validation/utils/validationObserverRef';

export default defineComponent({
  name: 'CourseSectionsDataForm',
  components: {DateTimePicker},
  props: {
    modelValue: {
      type: Array as PropType<EditableCourse['sections']>,
      required: true,
    },
    sectionTemplate: {
      type: Function as PropType<() => EditableCourse['sections'][number]>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  setup(props) {
    const vModelRef = useVModel(props, 'modelValue');

    const validationObserver = validationObserverRef();
    useEmitIsValid(useIsValidRef(validationObserver));

    const {editableEntity} = useSyncEditableObject({
      modelRef: vModelRef,
      cloneFn: (value?: EditableCourse['sections']) => (value ? cloneDeep(value) : []),
      editableEntityWatchOptions: {deep: true},
      autoSync: true,
    });

    function addSection() {
      editableEntity.value = [...editableEntity.value, props.sectionTemplate()];
    }

    function removeSection(index: number) {
      editableEntity.value.splice(index, 1);
    }

    return {vModelRef, editableEntity, validationObserver, addSection, removeSection};
  },
});
