














































import {defineComponent, PropType} from '@vue/composition-api';
import {inject} from '@/container';
import {UseInstitutionSelector} from '@/institutions/composables/UseInstitutionSelector';
import {default as InstitutionEditor} from '@/institutions/components/InstitutionEditor.vue';
import {events as InstitutionEditorEvents} from '@/institutions/composables/UseInstitutionEditor';
import {useVModel} from '@vueuse/core';

export default defineComponent({
  name: 'InstitutionSelector',
  components: {InstitutionEditor},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Number as PropType<number>,
      required: true,
    },
    errors: {
      type: Array as PropType<Array<String>>,
    },
  },
  setup(props, {emit}) {
    const {
      institutions,
      fetchInstitutions,
      searchTerm,
      createInstitutionDialog,
      internalValue,
      handledCreatedInstitution,
    } = inject(UseInstitutionSelector, useVModel(props, 'modelValue', emit));

    fetchInstitutions();

    return {
      internalValue,
      institutions,
      searchTerm,
      createInstitutionDialog,
      institutionCreatedEvent: InstitutionEditorEvents.INSTITUTION_EDITOR_INSTITUTION_CREATED,
      handledCreatedInstitution,
    };
  },
});
