import {useEditableObject, UseEditableObjectOptions} from '@/common/composables/useEditableObject';
import {Ref} from '@vue/composition-api';

export type EntityEditorOptions<Editable> = Omit<
  UseEditableObjectOptions<Editable>,
  'watchedRef' | 'editableEntityWatcherFn'
> & {
  modelRef: Ref<Editable>;
  autoSync?: boolean;
};

export function useSyncEditableObject<Editable>({
  modelRef,
  cloneFn,
  autoSync,
  ...editableOptions
}: EntityEditorOptions<Editable>) {
  const editableFunctions = useEditableObject<Editable>({
    watchedRef: modelRef,
    cloneFn,
    editableEntityWatcherFn: autoSync ? sync : undefined,
    ...editableOptions,
  });

  function sync() {
    modelRef.value = cloneFn(editableFunctions.editableEntity.value);
  }

  return {
    ...editableFunctions,
    sync,
  };
}
