



























import {computed, defineComponent, PropType, ref} from '@vue/composition-api';
import {Course} from '@/courses/models';
import StembleCard from '@/common/components/StembleCard.vue';
import DateTimePicker from '@/datetime/components/DateTimePicker.vue';
import CourseDataForm from '@/courses/components/CourseDataForm.vue';
import CourseSectionsDataForm from '@/courses/components/CourseSectionsDataForm.vue';
import CourseCreationWizard from '@/courses/components/CourseCreationWizard.vue';
import CourseAdvancedEditor from '@/courses/views/CourseAdvancedEditor.vue';

export default defineComponent({
  name: 'CourseSettings',
  components: {
    CourseAdvancedEditor,
    CourseCreationWizard,
    CourseSectionsDataForm,
    CourseDataForm,
    DateTimePicker,
    StembleCard,
  },
  props: {
    course: {
      type: Course as PropType<Course | null>,
      default: null,
    },
  },
  setup(props) {
    let title;

    if (props.course?.courseCode !== undefined) {
      title = `${props.course?.courseCode} - Settings | Stemble`;
    } else {
      title = `Course Creation | Stemble`;
    }

    document.title = title;

    const isNew = computed(() => props.course === null);
    const editorType = ref<null | 'editor' | 'wizard'>(isNew.value ? null : 'editor');

    return {
      isNew,
      editorType,
    };
  },
});
