import {getCurrentInstance, Ref, watch} from '@vue/composition-api';

export function useEmitIsValid(isValidRef: Ref<boolean | null>) {
  const vm = getCurrentInstance();

  watch(isValidRef, (value) => {
    if (value !== null) {
      vm?.emit('update:isValid', value);
    }
  });
}
