










































































import {defineComponent, PropType, ref, watch} from '@vue/composition-api';
import {EditableCourse} from '@/courses/composables/UseCourseEditor';
import {useVModel} from '@vueuse/core';
import DateTimePicker from '@/datetime/components/DateTimePicker.vue';
import {useNullifyRef} from '@/common/composables/useNullifyRef';
import {inject} from '@/container';
import {UseAllInstitutions} from '@/institutions/composables/UseAllInstitutions';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {useIsValidRef} from '@/form-validation/composables/useIsValidRef';
import {useEmitIsValid} from '@/form-validation/composables/useEmitIsValid';
import {validationObserverRef} from '@/form-validation/utils/validationObserverRef';
import {courseValidationRules} from '@/courses/form-validation';
import DateTimeInlineEditor from '@/datetime/components/DateTimeInlineEditor.vue';
import {FeatureFlag} from '@/feature-flags';
import InstitutionSelector from '@/institutions/components/InstitutionSelector.vue';

export default defineComponent({
  name: 'CourseDataForm',
  components: {
    InstitutionSelector,
    DateTimeInlineEditor,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    name: {
      type: String as PropType<EditableCourse['name']>,
    },
    courseCode: {
      type: String as PropType<EditableCourse['courseCode']>,
    },
    description: {
      type: String as PropType<EditableCourse['description']>,
    },
    startDate: {
      type: String as PropType<EditableCourse['startDate']>,
      default: '',
    },
    endDate: {
      type: String as PropType<EditableCourse['endDate']>,
      default: '',
    },
    institutionId: {
      type: Number as PropType<EditableCourse['institutionId']>,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    gradeDisplayOption: {
      type: String as PropType<String>,
    },
    draftsEnabled: {
      type: Boolean as PropType<boolean>,
    },
  },
  setup(props) {
    const {institutions} = inject(UseAllInstitutions);
    const selectGradeDisplay = useVModel(props, 'gradeDisplayOption');
    const startDateModel = useVModel(props, 'startDate');
    const endDateModel = useVModel(props, 'endDate');
    const draftsStatus = useVModel(props, 'draftsEnabled');
    const validationObserver = validationObserverRef();
    useEmitIsValid(useIsValidRef(validationObserver));

    return {
      FeatureFlag,
      courseValidationRules,
      validationObserver,
      institutions,
      startDateModel,
      endDateModel,
      nameModel: useNullifyRef(useVModel(props, 'name')),
      courseCodeModel: useVModel(props, 'courseCode'),
      descriptionModel: useNullifyRef(useVModel(props, 'description')),
      institutionIdModel: useVModel(props, 'institutionId'),
      selectGradeDisplay,
      draftsStatus,
    };
  },
});
