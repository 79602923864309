import {inject, makeDependency} from '@/container';
import {computed, Ref} from '@vue/composition-api';
import {useEntityEditor} from '@/common/composables/useEntityEditor';
import {DependencyType} from '@/container/types/DependencyType';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {LoadingFlagsService} from '@/loading/services/LoadingFlagsService';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import {Course} from '@/courses/models';
import {PatchCourseData} from '@/courses/types/PatchCourseData';
import {DeepRequired} from 'ts-essentials';
import {create} from '@/courses/api/orm/CourseApi';
import moment from 'moment';
import {FeatureFlag, FeatureFlagService} from '@/feature-flags';

export type EditableCourse = DeepRequired<PatchCourseData> & {
  subCourse: DeepRequired<PatchCourseData> | null;
};

export type UseCourseEditorOptions = {
  supportsSubCourse?: Ref<boolean>;
};

export const UseCourseEditor = makeDependency(
  (course: Ref<Course | null>, options?: UseCourseEditorOptions) => {
    return makeCourseEditor(
      course,
      options,
      inject(LoadingFlagsService),
      inject(ErrorReportingService)
    );
  }
);

export function makeCourseEditor(
  course: Ref<Course | null>,
  options: UseCourseEditorOptions | undefined,
  $loadingFlags: DependencyType<typeof LoadingFlagsService>,
  $errorReporting: DependencyType<typeof ErrorReportingService>
) {
  const DEFAULT_COURSE_INSTITUTION_ID = 0;

  const makeSection = (): EditableCourse['sections'][number] => ({
    id: 0,
    parentId: 0,
    name: '',
    description: null,
    color: null,
  });

  const isCreating = computed(
    () => !!$loadingFlags.isLoading(LoadingFlag.CourseEditorCreateCourse)
  );

  const isPatching = computed(
    () => !!$loadingFlags.isLoading(LoadingFlag.CourseEditorPatchingCourse)
  );

  const isSaving = computed(() => isCreating.value || isPatching.value);

  const supportsSubCourse = computed(() => options?.supportsSubCourse?.value ?? true);

  function makeCourse(): EditableCourse {
    return {
      id: 0,
      name: '',
      courseCode: '',
      description: null,
      color: null,
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().add('1', 'week').endOf('day').toISOString(),
      institutionId: DEFAULT_COURSE_INSTITUTION_ID,
      parentCourseId: null,
      subCourse: null,
      sections: [],
      gradeDisplay: 'percentage',
      draftsEnabled: true,
      inAppPaymentEnabled: false,
    };
  }

  function cloneFn(course?: Course | null): EditableCourse {
    if (!course) {
      return makeCourse();
    }
    return {
      id: course.id,
      name: course.name,
      courseCode: course.courseCode,
      description: course.description,
      color: course.color,
      startDate: course.startDate,
      endDate: course.endDate,
      institutionId: course.institutionId,
      parentCourseId: course.parentCourseId,
      subCourse: course.subCourse ? cloneFn(course.subCourse) : null,
      sections: [
        ...course.sections.map((s) => ({
          id: s.id,
          parentId: s.parentId,
          name: s.name,
          description: s.description,
          color: s.color,
        })),
      ],
      gradeDisplay: course.gradeDisplay,
      draftsEnabled: course.draftsEnabled,
      inAppPaymentEnabled: course.inAppPaymentEnabled,
    };
  }

  async function createCourse(...args: Parameters<typeof create>) {
    const entityCollections = await Course.api.create(...args);

    editableEntity.value.id = (entityCollections['Course'][0] as Course).id;

    return entityCollections;
  }

  async function createSubCourse(...args: Parameters<typeof create>) {
    const entityCollections = await Course.api.create(...args);

    const id = (entityCollections['Course'][0] as Course).id;
    if (editableEntity.value.subCourse) {
      editableEntity.value.subCourse.id = id;
    }

    return entityCollections;
  }

  async function createFn(editableCourse: EditableCourse) {
    await $loadingFlags
      .loadingHandler(LoadingFlag.CourseEditorCreateCourse, async () => {
        const {subCourse, ...parentCourse} = editableCourse;

        await createCourse(parentCourse);

        if (supportsSubCourse.value && subCourse && !hasParentCourse.value) {
          await createSubCourse({
            ...subCourse,
            parentCourseId: editableEntity.value.id,
          });
        }
      })
      .catch($errorReporting.errorDialogHandler);
  }

  async function patchFn(editableCourse: EditableCourse) {
    await $loadingFlags
      .loadingHandler(LoadingFlag.CourseEditorPatchingCourse, async () => {
        if (editableCourse.id !== undefined) {
          const {subCourse, ...parentCourse} = editableCourse;

          await Course.api.patch(editableCourse.id, parentCourse);

          if (supportsSubCourse.value && subCourse && !hasParentCourse.value) {
            if (subCourse.id) {
              await Course.api.patch(subCourse.id, subCourse);
            } else {
              await createSubCourse({...subCourse, parentCourseId: editableCourse.id});
            }
          }
        }
      })
      .catch($errorReporting.errorDialogHandler);
  }

  const {editableEntity, save, clear, reset} = useEntityEditor({
    watchedRef: course,
    watchNulls: true,
    cloneFn,
    createFn,
    patchFn,
  });

  editableEntity.value.sections?.sort((a, b) => a.name.localeCompare(b.name));
  const hasParentCourse = computed(() => editableEntity.value.parentCourseId !== null);

  function addSubCourse() {
    if (supportsSubCourse.value && !hasParentCourse.value) {
      editableEntity.value.subCourse = makeCourse();
      editableEntity.value.subCourse.institutionId = editableEntity.value.institutionId;
      editableEntity.value.subCourse.startDate = editableEntity.value.startDate;
      editableEntity.value.subCourse.endDate = editableEntity.value.endDate;
    }
  }

  function removeSubCourse() {
    editableEntity.value.subCourse = null;
  }

  function addSection() {
    editableEntity.value.sections?.push(makeSection());
    editableEntity.value.sections?.sort((a, b) => a.name.localeCompare(b.name));
  }

  function removeSection(index: number) {
    editableEntity.value.sections?.splice(index, 1);
  }

  return {
    editableCourse: editableEntity,
    isCreating,
    isPatching,
    isSaving,
    hasParentCourse,
    save,
    clear,
    reset,
    makeSection,
    addSubCourse,
    removeSubCourse,
    addSection,
    removeSection,
  };
}
