export const courseValidationRules = {
  name: 'min:2|required',
  courseCode: 'min:2|required',
  institutionId: 'required_integer_id',
  description: 'max:255',
  startDate: 'iso8601_date|required',
  endDate: 'iso8601_date|required',
  gradeDisplay: 'required',
  draftsEnabled: 'required',
};
