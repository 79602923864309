


























import {defineComponent, PropType} from '@vue/composition-api';
import {useVModel} from '@vueuse/core';
import {courseValidationRules} from '@/courses/form-validation';
import {validationObserverRef} from '@/form-validation/utils/validationObserverRef';
import {useEmitIsValid} from '@/form-validation/composables/useEmitIsValid';
import {useIsValidRef} from '@/form-validation/composables/useIsValidRef';

export default defineComponent({
  name: 'CourseCreationWizardBasicMetadata',
  props: {
    name: {
      type: String as PropType<string>,
    },
    courseCode: {
      type: String as PropType<string>,
    },
  },
  setup(props) {
    const courseNameModel = useVModel(props, 'name');
    const courseCodeModel = useVModel(props, 'courseCode');

    const validationObserver = validationObserverRef();
    useEmitIsValid(useIsValidRef(validationObserver));

    return {
      courseValidationRules,
      validationObserver,
      courseNameModel,
      courseCodeModel,
    };
  },
});
