













import {defineComponent, PropType, ref} from '@vue/composition-api';
import CourseSectionsDataForm from '@/courses/components/CourseSectionsDataForm.vue';
import {EditableCourse, UseCourseEditor} from '@/courses/composables/UseCourseEditor';
import {useVModel} from '@vueuse/core';
import {inject} from '@/container';

export default defineComponent({
  name: 'CourseCreationWizardSections',
  components: {CourseSectionsDataForm},
  props: {
    sections: {
      type: Array as PropType<EditableCourse['sections']>,
      default: [],
    },
  },
  setup(props) {
    const sectionsModel = useVModel(props, 'sections');
    const {makeSection} = inject(UseCourseEditor, ref(null));

    return {sectionsModel, makeSection};
  },
});
