var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('v-form',[_c('validation-provider',{attrs:{"rules":_vm.courseValidationRules.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('courseWizard.screens.basicMetadata.courseName.label'),"hint":_vm.$t('courseWizard.screens.basicMetadata.courseName.hint'),"disabled":_vm.disabled,"error-messages":errors},model:{value:(_vm.nameModel),callback:function ($$v) {_vm.nameModel=$$v},expression:"nameModel"}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.courseValidationRules.courseCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('courseWizard.screens.basicMetadata.courseCode.label'),"hint":_vm.$t('courseWizard.screens.basicMetadata.courseCode.hint'),"disabled":_vm.disabled,"error-messages":errors},model:{value:(_vm.courseCodeModel),callback:function ($$v) {_vm.courseCodeModel=$$v},expression:"courseCodeModel"}})]}}])}),(_vm.$features.isEnabled(_vm.FeatureFlag.InstitutionCreatorDropdown))?_c('validation-provider',{attrs:{"rules":_vm.courseValidationRules.institutionId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('institution-selector',{attrs:{"error":errors},model:{value:(_vm.institutionIdModel),callback:function ($$v) {_vm.institutionIdModel=$$v},expression:"institutionIdModel"}})]}}],null,false,776096075)}):_vm._e(),_c('validation-provider',{attrs:{"rules":_vm.courseValidationRules.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('courseSettings.description'),"disabled":_vm.disabled,"error-messages":errors},model:{value:(_vm.descriptionModel),callback:function ($$v) {_vm.descriptionModel=$$v},expression:"descriptionModel"}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.courseValidationRules.gradeDisplay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Grade display option:","items":['PERCENTAGE', 'FRACTION'],"error-messages":errors},model:{value:(_vm.selectGradeDisplay),callback:function ($$v) {_vm.selectGradeDisplay=$$v},expression:"selectGradeDisplay"}})]}}])}),_c('v-switch',{attrs:{"label":_vm.draftsStatus ? 'Drafts Enabled' : 'Drafts Disabled',"inset":""},model:{value:(_vm.draftsStatus),callback:function ($$v) {_vm.draftsStatus=$$v},expression:"draftsStatus"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.courseValidationRules.startDate}},[_c('date-time-inline-editor',{attrs:{"no-time":"","subtitle":_vm.$t('courseWizard.screens.dates.startDate.label')},model:{value:(_vm.startDateModel),callback:function ($$v) {_vm.startDateModel=$$v},expression:"startDateModel"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.courseValidationRules.endDate}},[_c('date-time-inline-editor',{attrs:{"no-time":"","subtitle":_vm.$t('courseWizard.screens.dates.endDate.label')},model:{value:(_vm.endDateModel),callback:function ($$v) {_vm.endDateModel=$$v},expression:"endDateModel"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }