import {makeDependency} from '@/container';
import {computed, ref, Ref, SetupContext} from '@vue/composition-api';
import {formatComponentDate, formatTime} from '@/datetime/utils/datetime';
import moment from 'moment';

export const UseDateTimeInlineEditor = makeDependency(
  (
    datetime: Ref<string | null>,
    emit: SetupContext['emit'],
    autoOpenTime: Ref<boolean> = ref(true),
    useTime: Ref<boolean> = ref(true)
  ) => {
    return makeDateTimeInlineEditorComposable(datetime, emit, autoOpenTime, useTime);
  }
);

export function makeDateTimeInlineEditorComposable(
  datetime: Ref<string | null>,
  emit: SetupContext['emit'],
  autoOpenTime: Ref<boolean> = ref(true),
  useTime: Ref<boolean> = ref(true)
) {
  const showDatePicker = ref(false);
  const showTimePicker = ref(false);
  const date = ref(datetime.value ? formatComponentDate(datetime.value) : null);
  const time = ref(datetime.value ? formatTime(datetime.value) : null);

  const formattedDate = computed(() =>
    datetime.value ? moment(datetime.value).format('ddd, MMM Do') : null
  );
  const formattedTime = computed(() => (datetime.value ? formatTime(datetime.value) : null));
  const updatedDateTime = computed(() =>
    date.value && time.value ? `${date.value}T${time.value}` : null
  );
  const formattedNewDateFromOld = computed(() =>
    updatedDateTime.value ? `(${moment(updatedDateTime.value).fromNow()})` : null
  );

  function syncDateTime() {
    emit(
      'update:modelValue',
      updatedDateTime.value ? moment(updatedDateTime.value).toISOString() : null
    );
    closePickers();
  }

  function confirmDate() {
    syncDateTime();

    if (useTime.value && autoOpenTime.value) {
      showTimePicker.value = true;
    }
  }

  function confirmTime() {
    syncDateTime();
  }

  function closePickers() {
    showTimePicker.value = false;
    showDatePicker.value = false;
  }

  function resetDateTimes(newValue: string | null): void {
    date.value = newValue ? formatComponentDate(newValue) : null;
    time.value = newValue ? formatTime(newValue) : null;
  }

  function cancelUpdate(): void {
    closePickers();
    resetDateTimes(datetime.value);
  }

  return {
    date,
    time,
    showDatePicker,
    showTimePicker,
    formattedDate,
    formattedTime,
    formattedNewDateFromOld,
    cancelUpdate,
    confirmDate,
    confirmTime,
    resetDateTimes,
  };
}
