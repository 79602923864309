












































































import {computed, defineComponent, PropType, toRef, watch} from '@vue/composition-api';
import {inject} from '@/container';
import {UseDateTimeInlineEditor} from '@/datetime/composables/UseDateTimeInlineEditor';

export default defineComponent({
  name: 'DateTimeInlineEditor',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: String as PropType<string | null>,
      default: null,
    },
    autoOpenTime: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    minimalInline: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    minDate: {
      type: String as PropType<string>,
      default: '',
    },
    maxDate: {
      type: String as PropType<string>,
      default: '',
    },
    ampmInTitle: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    timeFormat: {
      type: String as PropType<string>,
      default: 'ampm',
    },
    noTime: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    subtitle: {
      type: String as PropType<string>,
    },
  },
  setup(props, {emit}) {
    const {resetDateTimes, ...composable} = inject(
      UseDateTimeInlineEditor,
      toRef(props, 'modelValue'),
      emit,
      toRef(props, 'autoOpenTime'),
      computed(() => !props.noTime)
    );

    watch(toRef(props, 'modelValue'), resetDateTimes);

    return composable;
  },
});
