


















import {computed, defineComponent, PropType} from '@vue/composition-api';
import {EditableCourse} from '@/courses/composables/UseCourseEditor';
import {useTranslate} from '@/locales/composables/i18n';
import {formatComponentDate, FRIENDLY_DATE_YEAR_FORMAT} from '@/datetime/utils/datetime';
import Institution from '@/institutions/models/Institution';
import {inject} from '@/container';
import {FeatureFlag, FeatureFlagService} from '@/feature-flags';

export default defineComponent({
  name: 'CourseCreationWizardSummary',
  props: {
    course: {
      type: Object as PropType<EditableCourse>,
      required: true,
    },
  },
  setup(props) {
    const $t = useTranslate();
    const featureFlagService = inject(FeatureFlagService);

    const tableData = computed(() => {
      const result: [any, any][] = [
        [$t('courseWizard.courseName'), props.course.name],
        [$t('courseWizard.courseCode'), props.course.courseCode],
        [
          $t('courseSettings.sectionsHeader'),
          props.course.sections.length
            ? props.course.sections.map((s) => s.name).join(', ')
            : $t('none'),
        ],
        [
          $t('courseSettings.startDate'),
          formatComponentDate(props.course.startDate, FRIENDLY_DATE_YEAR_FORMAT),
        ],
        [
          $t('courseSettings.endDate'),
          formatComponentDate(props.course.endDate, FRIENDLY_DATE_YEAR_FORMAT),
        ],
      ];

      if (featureFlagService.isEnabled(FeatureFlag.InstitutionCreatorDropdown)) {
        result.push([
          $t('courseWizard.institution'),
          Institution.find(props.course.institutionId)?.name,
        ]);
      }

      if (props.course.subCourse) {
        result.push(
          [
            $t('courseWizard.labTutorial') + ' ' + $t('courseWizard.courseName'),
            props.course.subCourse.name,
          ],
          [
            $t('courseWizard.labTutorial') + ' ' + $t('courseWizard.courseCode'),
            props.course.subCourse.courseCode,
          ],
          [
            $t('courseWizard.labTutorial') + ' ' + $t('courseSettings.sectionsHeader'),
            props.course.subCourse.sections.length
              ? props.course.subCourse.sections.map((s) => s.name).join(', ')
              : $t('none'),
          ],
          [
            $t('courseWizard.labTutorial') + ' ' + $t('courseSettings.startDate'),
            formatComponentDate(props.course.subCourse.startDate, FRIENDLY_DATE_YEAR_FORMAT),
          ],
          [
            $t('courseWizard.labTutorial') + ' ' + $t('courseSettings.endDate'),
            formatComponentDate(props.course.subCourse.endDate, FRIENDLY_DATE_YEAR_FORMAT),
          ]
        );
      }

      return result;
    });
    return {
      tableData,
    };
  },
});
