



































import {defineComponent, PropType, toRef} from '@vue/composition-api';
import {inject} from '@/container';
import {Course} from '@/courses/models';
import {UseCourseCreationWizard} from '@/courses/composables/UseCourseCreationWizard';

export default defineComponent({
  name: 'CourseCreationWizard',
  props: {
    course: {
      type: Course as PropType<Course | null>,
      default: null,
    },
  },
  setup(props) {
    return inject(UseCourseCreationWizard, toRef(props, 'course'));
  },
});
