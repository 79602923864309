import {computed, Ref} from '@vue/composition-api';

export interface NullifyRefOptions {
  allowEmptyArray?: boolean;
  allowEmptyObject?: boolean;
}

export function useNullifyRef<T>(
  ref: Ref<T | null>,
  options: NullifyRefOptions = {}
): Ref<T | null> {
  return computed<T | null>({
    get(): T | null {
      return ref.value;
    },
    set(value: T | null): void {
      if (Array.isArray(value)) {
        if (value.length > 0 || (options.allowEmptyArray ?? true)) {
          ref.value = value;
          return;
        }
      } else if (typeof value === 'object') {
        if (value !== null && (Object.values(value).length > 0 || options.allowEmptyObject)) {
          ref.value = value;
          return;
        }
      } else if (typeof value === 'number' || value) {
        ref.value = value;
        return;
      }
      ref.value = null;
    },
  });
}
